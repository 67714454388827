<template>
  <div class="p-grid crud-demo">
    <div class="p-col-12">
      <div class="card">
        <h5>诉求管理</h5>
        <Toast/>
        <div class="p-formgroup-inline">
          <div class="p-field">
            <Button label="新增" icon="pi pi-plus" @click="openNew"/>
          </div>
          <div class="p-field">
            <InputText v-model="title" placeholder="关键字搜索"/>
          </div>
          <div class="p-field">
            <Calendar onfocus=this.blur() v-model="minDate" placeholder="开始日期" dateFormat="yy-mm-dd" showButtonBar="true"/> &nbsp;---
          </div>
          <div class="p-field">
            <Calendar onfocus=this.blur() placeholder="结束日期" v-model="maxDate" dateFormat="yy-mm-dd" showButtonBar="true"/>
          </div>
          <div class="p-field">
            <Dropdown v-model="responseState" :options="response" optionLabel="name" placeholder="响应状态"
                      option-value="code"/>
          </div>
          <div class="p-field">
            <Dropdown v-model="activityState" :options="state" optionLabel="name" placeholder="活动状态"
                      option-value="code"/>
          </div>
          <Button @click="lookUp()" label="查找"></Button>
        </div>

        <DataTable ref="dt" :value="tableData" dataKey="id" :rows="50"
                   :rowsPerPageOptions="[20,50]"
                   responsiveLayout="scroll">
          <Column field="id" header="序号"></Column>
          <Column field="title" header="标题"></Column>
          <Column field="type" header="类型"></Column>
          <Column field="content" header="内容摘要">
            <template #body="slotProps">
              <div v-html="slotProps.data.brief"></div>
            </template>
          </Column>
          <Column field="submitTime" header="提交时间">
              <template #body="slotProps">
                  {{new Date(+new Date(slotProps.data.submitTime) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')}}
              </template>
          </Column>
          <Column field="state" header="受理状态">
            <template #body="slotProps">
              <label v-if="slotProps.data.state==0">待响应</label>
              <label v-if="slotProps.data.state==1">已受理</label>
              <label v-if="slotProps.data.state==2">婉拒</label>
              <label v-if="slotProps.data.state==3">已关闭</label>
            </template>
          </Column>
          <Column>
            <template #body="slotProps">
              <Button class="p-button-sm p-mr-2" label="编辑"
                      @click="openNew(slotProps.data)"/>
              <Button class="p-button-sm" label="删除"
                      @click="removeSelected(slotProps.data)"/>
            </template>
          </Column>
        </DataTable>
        <Dialog  :style="{width: '600px'}" header="新增" :modal="true" class="p-fluid">
          <div class="p-field">
            <label for="name">标题</label>
            <InputText id="name" v-model="form.title" required="true"/>
          </div>
          <div class="p-field">
            <label for="description">类型</label>
            <Dropdown id="description" v-model="form.type" :options="types" optionValue="name" optionLabel="name"
                      placeholder="诉求类型"/>
          </div>
          <div>
            <label>简介</label>
            <InputText id="brief" v-model="form.brief" required="true"/>
          </div>
          <div class="p-field">
            <label class="p-mb-3">内容：</label>
            <my-editor :value="form.content" @input="setContent"></my-editor>
<!--            <Editor v-model="form.content" editorStyle="height: 320px"/>-->
          </div>
          <template #footer>
            <Button label="取消" icon="pi pi-times" class="p-button-text" @click="hideDialog"/>
            <Button label="保存" icon="pi pi-check" class="p-button-text" @click="saveNew()"/>
          </template>
        </Dialog>
        <Toast/>
      </div>
    </div>
  </div>
  <RequirementsRelease @close="closeDialog" v-if="dialogIsShow" ref="dialog"></RequirementsRelease>
  <Toast/>
</template>

<script>
import myEditor from "@/views/backend/myEditor";

import RequirementsRelease from "@/views/modules/businessRequirements/RequirementsRelease";
export default {
  components: {RequirementsRelease,myEditor},
  data() {
    return {
      title: null,
      minDate: null,
      maxDate: null,
      responseState: null,
      activityState: null,
      response: [
        {name: '待响应', code: '0'},
        {name: '已受理', code: '1'},
        {name: '已婉拒', code: '2'},
        {name: '已关闭', code: '3'}
      ],
      state: [
        {name: '活动状态'},
        {name: '未开始', code: '1'},
        {name: '已完结', code: '2'}
      ],
      tableData: null,
      form: {
        id: null,
        title: null,
        brief: null,
        type: null,
        content: null
      },
      dialogIsShow: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      product: {},
      filters: {},
      submitted: false,
      types: [
        {name: '类型'}
      ]
    }
  },
  productService: null,
  created() {
  },
  mounted() {
    this.loadTable()
  },
  methods: {
    loadTable() {
      this.$http.get('/demands/getAll').then((res) => {
        this.tableData = res.data
      })
    },
    setContent(val){
      this.form.content =val
    },
    saveNew() {
      this.$http.post('/companiesDemands/saveOrUpdate', this.form).then(() => {
        this.dialogIsShow = false;
        this.$toast.add({severity: 'success', summary: '添加成功', life: 3000})
        this.loadTable()
      });
    },
    openNew(data) {
      console.log(data)
      this.dialogIsShow = true;
      this.$nextTick(()=>{
        this.$refs.dialog.init(data)
      })

    },
    closeDialog(){
      this.dialogIsShow=false
      this.loadTable()
    },
    hideDialog() {
      this.dialogIsShow = false;
      this.submitted = false;
    },
    saveProduct() {
      this.submitted = true;
      if (this.product.name.trim()) {
        if (this.product.id) {
          this.product.inventoryStatus = this.product.inventoryStatus.value ? this.product.inventoryStatus.value : this.product.inventoryStatus;
          this.products[this.findIndexById(this.product.id)] = this.product;
          this.$toast.add({severity: 'success', summary: 'Successful', detail: 'Product Updated', life: 3000});
        } else {
          this.product.id = this.createId();
          this.product.code = this.createId();
          this.product.image = 'product-placeholder.svg';
          this.product.inventoryStatus = this.product.inventoryStatus ? this.product.inventoryStatus.value : 'INSTOCK';
          this.products.push(this.product);
          this.$toast.add({severity: 'success', summary: 'Successful', detail: 'Product Created', life: 3000});
        }
        this.dialogIsShow = false;
        this.product = {};
      }
    },
    editProduct(product) {
      this.product = {...product};
      this.dialogIsShow = true;
    },
    editSelected(row) {
      this.$http.get('/companiesDemands/get', {params: {id: row.id}}).then((res) => {
        this.dialogIsShow = true;
        this.form = res.data.data;
      });
    },
    removeSelected(row) {
      this.$http.get('/companiesDemands/remove', {params: {ids: row.id}}).then(() => {
        this.dialogIsShow = false;
        this.$toast.add({severity: 'success', summary: '删除成功', life: 3000})
        this.loadTable()
      });
    },
    deleteProduct() {
      this.products = this.products.filter(val => val.id !== this.product.id);
      this.deleteProductDialog = false;
      this.product = {};
      this.$toast.add({severity: 'success', summary: 'Successful', detail: 'Product Deleted', life: 3000});
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = '';
      var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },

    lookUp() {
      let min = new Date(this.minDate == "" ? 1970-1-1 : this.minDate);
      let max = new Date(this.maxDate == "" ? new Date() : this.maxDate);
      min = min.getFullYear() + '-' + (min.getMonth() + 1) + '-' + min.getDate();
      max = max.getFullYear() + '-' + (max.getMonth() + 1) + '-' + max.getDate();
      this.$http.get('/companiesDemands/termQuery', {
        params: {
          'title': this.title,
          'minDate': min,
          'maxDate': max,
          'state': this.responseState,
          'enable': this.activityState
        }
      }).then((res) => {
        this.tableData = res.data
        this.$toast.add({severity: 'success', summary: '成功', detail: '查询成功', life: 3000});
      })
    }

  }
}
</script>

<style scoped lang="scss">
.table-header {
  display: flex;
  justify-content: space-between;
}

.product-image {
  width: 100px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

.p-dialog .product-image {
  width: 150px;
  margin: 0 auto 2rem auto;
  display: block;
}

.confirmation-content {
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-badge {
  border-radius: 2px;
  padding: .25em .5rem;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 12px;
  letter-spacing: .3px;

  &.status-instock {
    background: #C8E6C9;
    color: #256029;
  }

  &.status-outofstock {
    background: #FFCDD2;
    color: #C63737;
  }

  &.status-lowstock {
    background: #FEEDAF;
    color: #8A5340;
  }
}

::v-deep(.p-toolbar) {
  flex-wrap: wrap;

  .p-button {
    margin-bottom: .25rem;
  }
}
</style>
